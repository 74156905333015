@import url("../../../App.css");

.qr-button {
  width: 25%;
  background-color: var(--pastel-002);
  font-size: 14px;
  color: var(--white);
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  margin-top: 15px;
}

.qr-button:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

@media (max-width: 700px) {
  .qr-button {
    font-size: 14px;
    width: 55%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .qr-button {
    font-size: 14px;
    width: 40%;
  }
}
