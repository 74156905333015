@import url("../../../../App.css");

.loc-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.add-loc-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.marg-right {
  margin-right: 10px;
  width: 65%;
  font-size: 15px;
}

.add-loc-detail {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 7px;
  font-size: 12px;
  color: var(--pastel-004);
  font-weight: 500;
  cursor: pointer;
}

.loc-table-col-title {
  color: var(--pastel-004);
  font-size: 16px;
}

.loc-table-col-item {
  color: var(--black);
  font-size: 14px;
}

.loc-table-row-container {
  cursor: pointer;
}

.loc-active {
  color: green;
  font-size: 14px;
  font-weight: 500;
}

.loc-in-active {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.loc-list-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.loc-list-button {
  font-size: 13.5px;
  color: var(--pastel-004);
  border-radius: 4px;
  border-style: solid;
  border-color: var(--pastel-004);
  background-color: var(--white);
  padding: 2px 15px;
  border-width: 0.75px;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
