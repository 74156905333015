@import url("../../../App.css");

.afr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em;
}

.afr-img-contain {
  width: 12%;
  height: 5%;
}
