@import url("../../../../App.css");

.add-sc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.sc-input {
  width: 100%;
  margin-bottom: 1.2em;
}

.asc-marg-right {
  width: 100%;
}

.sc-input-text {
  font-size: 13px;
  color: black;
}

.sc-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
  margin-top: 1.2em;
}

.sc-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.sc-button {
  width: 40%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
}

.sc-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

@media (max-width: 700px) {
  .sc-button {
    font-size: 14px;
    width: 55%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .sc-button {
    font-size: 15px;
    width: 50%;
  }
}
