@import url("../../../App.css");
.su-contentAreaSignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.su-screen-label {
  font-size: 26px;
  font-weight: 600;
  color: var(--red);
  margin-top: 1.5em;
}

.su-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.su-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.su-img {
  height: 100%;
  width: 100%;
}

.su-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.su-input {
  width: 49%;
  font-size: 14px;
}

.su-input-full {
  width: 100%;
  font-size: 14px;
}

.su-confirmPass {
  font-size: 15px;
}

.

.su-mobile-input {
  border-color: var(--red);
  border-width: 1px;
}

.su-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.su-select {
  width: 49%;
  font-size: 14px;
}

.su-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
}

@media (max-width: 700px) {
  .su-img-contain {
    width: 40%;
    height: 40%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .su-img-contain {
    width: 40%;
    height: 40%;
  }
}
