@import url("../../../../App.css");

.dtl-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.dtl-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dtl-refresh-container {
  height: 25px;
  padding-bottom: 4px;
  border-color: var(--orange);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 35%;
  align-items: center;
  cursor: pointer;
}

.dtl-img {
  width: 100%;
  height: 100%;
}

.dtl-img-container {
  width: 20px;
  height: 20px;
}

.dtl-refresh-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--orange);
  margin-top: 5px;
  margin-left: 5px;
}

.add-dtl-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.add-dtl-btn {
  color: var(--white);
  background-color: var(--orange);
  font-size: 14px;
  width: 25%;
  height: 35px;
  align-self: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--orange);
}

.add-dtl-btn:hover {
  color: var(--orange);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--orange);
  border-width: 1px;
}

.dtl-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dtl-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.dtl-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.dtl-list-select {
  font-size: 13px;
  width: 100%;
}

.dtl-list-video-player {
  border: 1px solid #cddfe7;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 80px;
}

.dtl-list-remote-video-player {
  border: 1px solid #cddfe7;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 80px;
  margin-right: 15px;
}

.dtl-video-container {
  position: absolute;
  bottom: 20px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dtl-call-option-icon-container {
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.dtl-call-option-icon {
  width: 100%;
  height: 100%;
}

.dtl-call-option-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5;
}

@media (max-width: 700px) {
  .add-dtl-btn {
    width: 40%;
    font-size: 12px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .add-dtl-btn {
    width: 40%;
    font-size: 12px;
  }
}
