@import url("../../../../App.css");

.p-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.add-p-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.p-list-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.p-image-container {
  width: 90px;
  height: 90px;
}

.p-item-container {
  height: 90px;
  vertical-align: middle;
}

.p-availability-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.p-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
}

.p-card {
  width: 15em;
  border-color: var(--pastel-003);
  border-width: 1px;
  padding: 7px;
  margin-right: 5px;
}

.p-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.p-card-label {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
}

.p-card-label-val {
  font-size: 14px;
  color: var(--black);
}

.p-card-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

.p-active {
  color: green;
  font-size: 13px;
  font-weight: 500;
}

.p-in-active {
  color: red;
  font-size: 13px;
  font-weight: 500;
}

.p-card-btn {
  color: var(--white);
  background-color: var(--pastel-002);
  font-size: 13px;
  width: 45%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-002);
}

.p-card-btn-full {
  color: var(--white);
  background-color: var(--pastel-002);
  font-size: 13px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-002);
}

.p-card-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-color: var(--pastel-002);
}

.p-list-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.p-list-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.p-list-select {
  font-size: 13px;
  width: 100%;
}

.p-list-card-image {
  height: 180px;
  object-fit: contain;
  width: 100%;
}

.p-list-price-disclaimer {
  font-size: 12px;
  color: var(--orange);
}

.p-availability-container {
  background-color: var(--white);
  color: var(--pastel-002);
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 50px;
  border-color: var(--pastel-002);
  border-width: 0.75px;
  border-style: solid;
  margin-top: 5px;
  margin-left: 5px;
}

.p-table-container {
  width: 100%;
  overflow: scroll;
}

.p-price-warning {
  font-size: 14px;
  color: var(--pastel-003);
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .p-card {
    width: 12em;
    margin-bottom: 5px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
}
