@import url("../../../App.css");

.col-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.col-item-label {
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.col-amount-label {
  font-size: 14px;
  margin-top: 2px;
  color: var(--pastel-004);
}

.col-icon-label {
  font-size: 14px;
  color: var(--pastel-004);
  margin-left: 10px;
}

.add-col-row {
  display: flex;
  flex-direction: column;
  border-color: var(--pastel-001);
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.col-status-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  padding: 2px 60px;
  border-radius: 2px;
  background-color: var(--red);
  color: var(--white);
  font-size: 13px;
  align-items: center;
}

.col-status-container-yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.col-status-container-green {
  background-color: var(--green);
  color: var(--white);
}

.col-status-container-orange {
  background-color: var(--orange);
  color: var(--white);
}

.col-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.col-image {
  width: 100%;
  height: 100%;
}

.col-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.add-col-btn {
  margin-top: 15px;
  align-self: flex-end;
  color: var(--white);
  background-color: var(--pastel-004);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-004);
}

.add-col-btn:hover {
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-width: 1px;
}

.col-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.col-icon-container {
  width: 20px;
  height: 20px;
}

.col-icon-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

@media (max-width: 700px) {
  .add-col-btn {
    width: 50%;
  }

  .col-card {
    width: 12em;
    margin-bottom: 5px;
  }

  .add-col-row {
    /* margin-left: 0%;
    margin-right: 0%; */
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .add-col-btn {
    width: 50%;
  }

  .add-col-modal {
    top: 30%;
    bottom: 30%;
  }
}
