@import url("../../../App.css");

.mcp-logo-container {
  height: 50px;
  width: fit-content;
}

.mcp-restaurant-label {
  font-size: 1.6rem;
  margin-left: 10px;
  line-height: 1.05;
}

.mcp-category-parent-container {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.mcp-category-scroll-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  background-color: #f8f8f8;
  padding: 10px 5px;
}

.mcp-category-item {
  padding: 2px 15px;
  margin-right: 7px;
  border-radius: 20px;
  border-style: solid;
  border-width: 0.75px;
  border-color: #9c9c9c;
  background-color: var(--white);
  font-size: 14px;
  color: #9c9c9c;
  white-space: nowrap;
  cursor: pointer;
}

.mcp-category-item-selected {
  border-color: var(--pastel-002);
  background-color: var(--pastel-002);
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
}

.mcp-product-list-parent-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.mcp-product-row-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-color: var(--pastel-001);
  background-color: #fbfbfb;
  border-width: 0.5px;
  border-style: solid;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.mcp-product-row-item-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.mcp-product-image-container {
  height: 110px;
  width: 110px;
  position: relative;
}

.mcp-product-text-parent-container {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  flex-grow: 1;
}

.mcp-product-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
  flex-grow: 1;
  padding-left: 10px;
}

.mcp-product-name {
  font-size: 18px;
  color: var(--black);
  font-weight: 600;
}

.mcp-product-price-name {
  font-size: 14px;
  color: #494949;
}

.mcp-points-container {
  font-size: 12px;
  color: var(--black);
  border-radius: 20px;
  background-color: #c6c6c6;
  padding: 1.4px 15px;
  width: fit-content;
}

.edit-image-button-bl {
  position: absolute;
  /* position the button absolutely within the banner */
  bottom: 1px;
  /* position the button 10px from the top */
  right: 5px;
  /* position the button 10px from the right */
  padding: 2px 14px;
  background-color: var(--pastel-002);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 14px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
}

.mcp-option-type-container-green {
  font-size: 11px;
  color: var(--white);
  margin-bottom: 10px;
  background-color: var(--green);
  width: fit-content;
  padding: 2px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.mcp-option-type-container-red {
  font-size: 11px;
  color: var(--white);
  margin-bottom: 10px;
  background-color: var(--logo-red);
  width: fit-content;
  padding: 2px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.mcp-option-based-pricing-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mcp-option-price-label {
  font-size: 14px;
  color: #494949;
  margin-right: 5px;
  margin-top: 4px;
  border-radius: 4px;
  border-color: var(--gray);
  border-width: 0.75px;
  border-style: solid;
  padding: 2px 5px;
}
