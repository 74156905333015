@import url("../../../../App.css");
.contentAreaLDT {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ldt-screen-label {
  font-size: 26px;
  font-weight: 600;
  color: var(--red);
  margin-top: 1.5em;
}

.ldt-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.ldt-img {
  height: 100%;
  width: 100%;
}

.ldt-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4em;
}

.ldt-button {
  width: 45%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
}

.ldt-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.ldt-input {
  font-size: 14px;
}

@media (max-width: 700px) {
  .ldt-button {
    font-size: 14px;
  }

  .ldt-img-contain {
    width: 40%;
    height: 40%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .ldt-button {
    font-size: 15px;
  }

  .ldt-img-contain {
    width: 40%;
    height: 40%;
  }
}
