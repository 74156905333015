.pp-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100vh;
}

.pp-label {
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 10px;
}

.pp-updateLabel {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
}

.pp-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.pp-first-inline {
  margin-left: 12px;
}

.pp-subitem {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
  font-size: 16px;
}

.pp-text-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.pp-text-label-italic {
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
}

.pp-text-bold {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
}

.pp-text-span-bold {
  font-size: 16px;
  font-weight: bold;
}

.pp-vertical-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pp-select-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pp-select {
  width: 70%;
  font-size: 14px;
}
