@import url("../../../App.css");

.ao-location-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ao-location-country-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--pastel-003);
  border-width: 0.75px;
  padding: 2px 10px;
}

.ao-location-country-content-container {
  display: flex;
  flex-direction: column;
}

.ao-location-remove-country-icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.ao-location-remove-state-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.ao-location-country-label {
  font-size: 15px;
  color: var(--black);
}

.ao-location-country-state-container {
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--pastel-001);
  border-width: 0.75px;
  padding: 2px 10px;
}

.ao-location-country-state-content-container {
  display: flex;
  flex-direction: column;
}

.ao-location-state-label {
  font-size: 14.1px;
  color: var(--black);
}

.ao-location-city-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5px;
}

.ao-location-city-label {
  display: flex;
  flex-direction: row;
  font-size: 14.1px;
  color: var(--black);
  margin-right: 5px;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--pastel-002);
  border-width: 0.75px;
  padding: 2px 10px;
}
