@import url("../../../App.css");

.contact-hero {
  background: var(--orange);
  height: 60vh;
  position: relative;
  color: #fff;
}
.contact-hero .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 1.2rem;
}

.contact-label-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.h-line-white {
  width: 30px;
  height: 2px;
  background: var(--white);
  align-self: center;
  margin-left: 10px;
}

.contact-label {
  font-size: 1rem;
  font-weight: 700;
  color: var(--white);
}

.have-question-label {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--white);
}

.have-question-sub-text {
  font-size: 16px;
  color: var(--white);
}

.need-ques-container {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
}

.need-help-label {
  color: var(--black);
  font-weight: 700;
  font-size: 1.8rem;
}

.need-help-label-sub {
  color: var(--gray);
  font-weight: 300;
  font-size: 1.8rem;
}

@media (max-width: 700px) {
  .have-question-label {
    font-size: 1.9rem;
  }

  .need-help-label {
    font-size: 1.4rem;
  }

  .need-help-label-sub {
    font-size: 1.4rem;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
}
