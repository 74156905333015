@import url("../../../App.css");

.ol-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
.ol-center-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ol-vertical-center-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
