@import url("../../../../../App.css");

.obl-search-parent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.obl-search-container {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.obl-user-row-parent-container {
  display: flex;
  flex-direction: column;
}

.obl-user-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--pastel-001);
  padding: 5px 10px;
}

.obl-user-row-btn-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.obl-user-row-btn-container {
  text-align: center;
  color: var(--white);
  background-color: var(--pastel-003);
  padding: 3px 15px;
  font-size: 13.5px;
  border-radius: 4px;
  cursor: pointer;
}

.obl-user-row-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
