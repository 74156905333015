@import url("../../../App.css");

input:focus {
  border-color: var(--pastel-004);
}

select:focus {
  border-color: var(--pastel-004);
}

.qc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.qc-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  width: 90%;
}

.qc-img-container {
  width: 200px;
  height: 100px;
}

.qc-img {
  width: 100%;
  height: 100%;
}

a {
  font-size: 14px;
  background-color: var(--pastel-004);
  color: var(--white);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  text-decoration: none;
  border-color: var(--pastel-004);
  cursor: pointer;
}

.select-dropdown {
  width: 80%;
  margin-top: 15px;
  font-size: 14px;
  align-self: center;
}

.qr-item-row-container {
  width: 80%;
  margin-top: 15px;
  align-self: center;
}

.qr-detail-label {
  font-size: 12px;
  margin-bottom: 7px;
  color: var(--pastel-004);
  font-weight: bold;
}

.qr-detail-item-label {
  font-size: 14px;
  color: var(--pastel-004);
  font-weight: bold;
}

.qr-detail-item-val {
  font-size: 15px;
  margin-bottom: 5px;
  color: var(--black);
}

.qr-business-label {
  margin-top: 10px;
  font-size: 1.7rem;
  color: var(--black);
  font-weight: 600;
  text-align: center;
}

.qr-business-location-label {
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: var(--black);
  text-align: center;
}

.qr-qme-label {
  text-align: end;
  margin-top: 7px;
  font-size: 0.8rem;
  color: var(--black);
  font-style: italic;
}

.qr-qme-name-label {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--black);
  font-style: normal;
}

.qr-code-container {
  padding: 7px 20px 5px 20px;
  background-color: var(--white);
}

.qrCodeContainer {
  text-align: center;
}

.qrCode-center-image {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
  .qr-item-label-val {
    display: block;
  }
}
