@import url("../../../App.css");
.si-contentAreaSignIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.si-sign-in-screen-label {
  font-size: 26px;
  font-weight: 600;
  color: var(--red);
  margin-top: 1.5em;
}

.si-sign-in-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.si-sign-in-img {
  height: 100%;
  width: 100%;
}

.si-sign-in-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4em;
}

.si-sign-in-other-opt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 14px;
}

.si-sign-in-col-button-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.si-forgot-password-label {
  color: var(--pastel-003);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  letter-spacing: 0.3px;
  text-decoration: underline;
  text-underline-offset: 2.5px;
}

.sign-in-parent-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.sign-in-img-layout-container {
  width: 60%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-content-layout-container {
  width: 40%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 3rem;
}

.sign-in-heading {
  font-size: 2rem;
  color: #2f2f2f;
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
}

.sign-in-heading-label {
  color: var(--orange);
  font-weight: 600;
}

.sign-in-or-label {
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #2f2f2f;
  margin-top: 6px;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
}

.sign-in-create-account-label {
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #252525;
}

.sign-in-create-account-label-italic {
  font-size: 16.5px;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--orange);
  font-style: italic;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}

.sign-in-logo-container {
  height: 50px;
  margin-bottom: 3rem;
}

.sign-in-sub-text-label {
  font-size: 15px;
  letter-spacing: 0.35px;
}

@media (max-width: 767px) {
  .si-sign-in-img-contain {
    width: 40%;
    height: 40%;
  }

  .sign-in-parent-container {
    flex-direction: column;
  }

  .sign-in-img-layout-container {
    display: none;
  }

  .sign-in-content-layout-container {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 1rem;
  }

  .sign-in-heading {
    font-size: 1.8rem;
    color: #2f2f2f;
    letter-spacing: 0.4px;
    margin-bottom: 1rem;
  }

  .sign-in-heading-label {
    color: var(--orange);
    font-weight: 600;
  }

  .sign-in-logo-container {
    margin-bottom: 2rem;
  }

  /* .sign-in-logo-container {
    width: 40%;
    height: 40%;
  } */
}

@media (min-width: 768px) and (max-width: 1023px) {
  .si-sign-up-button {
    font-size: 14px;
  }

  .si-sign-in-img-contain {
    width: 40%;
    height: 40%;
  }

  .sign-in-parent-container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .sign-in-img-layout-container {
    width: 30%;
    height: 100%;
  }

  .sign-in-content-layout-container {
    width: 70%;
    height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sign-in-heading {
    font-size: 1.9rem;
    color: #2f2f2f;
    letter-spacing: 0.4px;
    margin-bottom: 1rem;
  }

  .sign-in-heading-label {
    color: var(--orange);
    font-weight: 600;
  }

  /* .sign-in-logo-container {
    width: 25%;
    height: 30%;
  } */
}
