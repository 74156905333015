@import url("../../../../App.css");

.resd-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.resd-detail-container {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.resd-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  align-self: center;
}

.resd-row-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-width: 0.5px;
  border-style: solid;
  border-color: var(--pastel-002);
}

.resd-dash-header-label-left {
  font-size: 1.5em;
  align-self: flex-start;
  color: var(--black);
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 10px;
}

.resd-dash-header-label-detail {
  font-size: 1em;
  align-self: flex-start;
  color: var(--black);
  margin-bottom: 10px;
}

.resd-row-image-container {
  display: flex;
  margin-top: 5px;
  flex-direction: row;
  flex-wrap: wrap;
}

.resd-image-container {
  width: 150px;
  height: 150px;
}

.resd-image {
  width: 100%;
  height: 100%;
}

.resd-icon-container {
  width: 20px;
  height: 20px;
}

.resd-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 2.5rem;
}

.resd-tag-row-item {
  background-color: var(--pastel-004);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  color: var(--white);
  margin-right: 5px;
  margin-top: 5px;
}

@media (max-width: 700px) {
  .resd-dash-header-label-left {
    font-size: 0.8em;
  }

  .resd-dash-header-label-detail {
    font-size: 0.6em;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
}
