@import url("../../../App.css");

.kol-update-btn {
  font-size: 14px;
  color: var(--pastel-004);
  background-color: var(--white);
  border-style: solid;
  border-width: 0.75px;
  border-radius: 4px;
  padding: 2px 15px;
  border-color: var(--pastel-004);
}
