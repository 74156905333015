@import url("../../../App.css");

.rtd-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.rtd-content {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  width: 90%;
}

.rtd-img-container {
  width: 200px;
  height: 100px;
}

.rtd-img {
  width: 100%;
  height: 100%;
}

.rtd-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: bold;
}

.rtd-content-text {
  font-size: 16px;
  color: var(--pastel-004);
  margin-bottom: 5px;
}

.rtd-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rtd-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: var(--gray);
  border-width: 0.75px;
  border-style: solid;
  font-size: 15px;
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: scroll;
}

.rtd-message-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  border-width: 0.75px;
  border-color: var(--gray);
  border-style: solid;
  margin-bottom: 5px;
  padding: 7px;
  overflow-x: scroll;
}

.rtd-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
