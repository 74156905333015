@import url("../../../App.css");

.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.dash-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.dash-header-label {
  font-size: 1.2em;
  margin-top: 10px;
  align-self: center;
}

.dash-header-label-left {
  font-size: 1em;
  align-self: flex-start;
  color: var(--white);
}

.dash-header-label-val {
  font-size: 3em;
  display: flex;
  flex-direction: row-reverse;
  color: var(--white);
  margin-top: 10px;
}

.dash-card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  align-self: flex-start;
  flex-wrap: wrap;
}

.dash-card {
  width: 20em;
  background-color: var(--pastel-004);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  margin-top: 5px;
  padding: 5px;
}

.dash-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dash-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dash-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.dash-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.dash-list-select {
  font-size: 13px;
  width: 100%;
}

@media (max-width: 700px) {
  .marg-left {
    margin-left: 10px;
  }

  .dash-header-label-val {
    font-size: 2.2em;
    margin-top: 7px;
    display: flex;
    flex-direction: row-reverse;
  }

  .dash-card {
    margin-bottom: 7px;
    width: 14em;
    margin-top: 5px;
  }

  .dash-header-label-left {
    font-size: 0.8em;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
}
