.opc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.add-c-img {
  width: 100%;
  height: 100%;
}

.opc-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opc-modal-img-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 130px;
  margin-top: 10px;
}

.opc-modal-order-success {
  font-size: 1.7em;
  color: var(--red);
  font-weight: 600;
  margin-top: 15px;
  text-align: center;
}

.opc-modal-order-subtext {
  font-size: 16px;
  color: var(--black);
  margin-top: 10px;
  text-align: center;
}

.opc-close-btn {
  background-color: var(--green-success);
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: var(--white);
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  align-self: center;
  margin-top: 1rem;
  cursor: pointer;
}

.opc-try-again-btn {
  background-color: var(--green-success);
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: var(--white);
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  align-self: center;
  margin-top: 3rem;
  cursor: pointer;
}

@media (min-width: 701px) and (max-width: 900px) {
  .opc-close-btn {
    width: 50%;
  }

  .opc-try-again-btn {
    width: 50%;
  }
}

@media (max-width: 700px) {
  .opc-close-btn {
    width: 50%;
  }

  .opc-try-again-btn {
    width: 50%;
  }
}

@media (max-width: 660px) {
  .opc-modal-order-success {
    font-size: 1.2em;
  }

  .opc-modal-order-subtext {
    font-size: 14px;
  }

  .opc-modal-order-number {
    font-size: 14px;
  }
}
