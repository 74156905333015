@import url("../../../App.css");

.crl-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
}

.crl-left-margin {
  padding-left: 1em;
}

.crl-right-margin {
  margin-right: 1em;
}

.crl-qty-update-label {
  color: var(--pastel-004);
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.crl-item-label {
  font-size: 19px;
  margin-bottom: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.crl-amount-label {
  font-size: 16px;
  color: var(--pastel-004);
}

.add-crl-row {
  display: flex;
  flex-direction: column;
  border-color: var(--pastel-001);
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.crl-update-qty-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.add-crl-btn {
  margin-top: 15px;
  align-self: flex-end;
  color: var(--white);
  background-color: var(--pastel-002);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-002);
}

.add-crl-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.add-crl-btn-row {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  align-items: center;
}

.add-crl-img-container {
  width: 24px;
  height: 24px;
}

.add-crl-img {
  width: 100%;
  height: 100%;
}

.crl-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.crl-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.crl-customization-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray);
}

.crl-customization-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.crl-customization-item {
  font-size: 14px;
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--gray);
  margin-right: 4px;
  margin-top: 4px;
  padding: 2px 10px;
}

@media (max-width: 700px) {
  .add-crl-btn {
    width: 50%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .add-crl-btn {
    width: 50%;
  }
}
