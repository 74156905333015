@import url("../../../App.css");

.col-d-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.col-d-data-container {
  display: flex;
  flex-direction: column;
}

.col-d-item-label {
  font-size: 15px;
  margin-top: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.col-d-item-label-container {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.col-d-amount-label {
  font-size: 14px;
  margin-top: 2px;
  color: var(--pastel-004);
}

.col-d-amount-label-light {
  font-size: 14px;
  margin-top: 2px;
  color: var(--gray);
}

.add-col-d-row {
  display: flex;
  flex-direction: column;
  border-color: var(--pastel-001);
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 7px 10px;
  margin-top: 5px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.col-d-item-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}
.col-d-item-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: var(--pastel-004);
  padding-top: 3px;
  padding-bottom: 3px;
  border-width: 0.75px;
  width: 50%;
  color: var(--black);
}

.col-d-price-container {
  margin-top: 5px;
  color: var(--black);
  font-size: 16px;
  display: flex;
  flex-direction: row-reverse;
}

.col-d-price-container-gt {
  font-size: 17px;
  font-weight: 600;
}

.col-d-status-container {
  align-self: flex-end;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 50%;
  background-color: var(--red);
  color: var(--white);
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-d-status-container-yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.col-d-status-container-green {
  background-color: var(--green);
  color: var(--white);
}

.col-d-status-container-orange {
  background-color: var(--orange);
  color: var(--white);
}

.col-d-modal {
  position: absolute;
  top: 25%;
  left: 5%;
  right: 5%;
  bottom: 25%;
  background-color: var(--white);
  border-radius: 4px;
  padding: 10px;
}

.col-d-modal-overlay {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.col-d-modal-container {
  display: flex;
  flex-direction: column;
}

.col-d-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.col-d-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.add-col-d-btn {
  margin-top: 15px;
  align-self: flex-end;
  color: var(--white);
  background-color: var(--pastel-002);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-002);
}

.add-col-d-btn-left {
  align-self: center;
  margin-top: 3em;
  height: 35px;
}

.add-col-d-btn-column {
  margin-top: 10px;
}

.add-col-d-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.col-d-img {
  width: 100%;
  height: 100%;
}

.add-review-label {
  margin-bottom: 5px;
  color: var(--red);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.col-d-item-status {
  align-self: flex-end;
  background-color: var(--blue-general);
  color: var(--white);
  font-size: 14px;
  padding: 2px 35px;
  border-radius: 2px;
}

.col-d-item-status-green {
  align-self: flex-end;
  background-color: var(--green-success);
  color: var(--white);
  font-size: 14px;
  padding: 2px 35px;
  border-radius: 2px;
}

.col-btn-container-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .add-col-d-btn {
    width: 50%;
  }

  .add-col-d-row {
    /* margin-left: 0%;
    margin-right: 0%; */
  }

  .col-d-modal {
    top: 20%;
    bottom: 20%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .add-col-d-btn {
    width: 50%;
  }
}
