@import url("../../../../App.css");

.carousel-container {
  height: 520px;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  margin-left: 10px;
  margin-right: 10px;
}

.caption-label {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--black);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  width: 680px;
  background-color: var(--pastel-001);
}

.carousel-image-container {
  height: 490px;
  width: 680px;
}

@media (max-width: 700px) {
  .carousel-image-container {
    height: 202px;
    width: 280px;
  }

  .carousel-container {
    height: 220px;
  }

  .caption-label {
    width: 280px;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
  .carousel-image-container {
    height: 418px;
    width: 580px;
  }

  .carousel-container {
    height: 430px;
  }

  .caption-label {
    width: 580px;
  }
}
