@import url("../../App.css");

.common-form-border {
  width: 90%;
  align-self: center;
  padding: 15px 10px;
  margin: 5px 0px;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

@media (max-width: 700px) {
  .common-form-border {
    width: 95%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .common-form-border {
    width: 90%;
  }
}
