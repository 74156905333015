@import url("../../../App.css");

.rss-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.rss-input-half {
  width: 49%;
}

.rss-input-row-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2em;
}

.rss-input {
  width: 100%;
  margin-bottom: 1.2em;
}

.rss-input-text {
  font-size: 13px;
  color: black;
}

.rss-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.rss-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.rss-button {
  width: 40%;
  background-color: var(--pastel-004);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
  border-color: var(--pastel-004);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-width: 1px;
}

.rss-button:hover {
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-width: 1px;
}

.rss-select {
  width: 100%;
}

.rss-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: bold;
}

.rss-text {
  font-size: 14px;
  color: black;
}

.rss-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
  color: var(--pastel-004);
}

.rss-checkbox-label-black {
  color: var(--black);
}

@media (max-width: 700px) {
  .rss-button {
    font-size: 14px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .rss-button {
    font-size: 15px;
  }
}
