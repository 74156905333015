@import url("../../../../App.css");

.edit-loc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.eloc-warning-label {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: var(--red);
}

.eloc-field-text-label {
  font-size: 12px;
  color: var(--black);
  margin-top: 7px;
}

.eloc-payment-mode-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.eloc-payment-mode-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-color: var(--pastel-003);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  color: var(--pastel-003);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.eloc-payment-mode-n-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-color: var(--pastel-003);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  color: var(--pastel-003);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 5px;
  margin-top: 5px;
}

.eloc-payment-img-container {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.eloc-img {
  width: 100%;
  height: 100%;
}

.eloc-payment-mode-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.eloc-payment-mode-input {
  font-size: 14px;
}

.eloc-payment-mode-input-div-container {
  width: 49%;
}

.eloc-payment-mode-input-container {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-loc-input {
  width: 100%;
  font-size: 15px;
}

.edit-loc-marg-right {
  width: 100%;
  font-size: 15px;
}

.edit-loc-input-text {
  font-size: 15px;
  color: var(--black);
}

.edit-loc-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.edit-loc-add-button {
  width: 18%;
  margin-left: 10px;
  background-color: var(--pastel-004);
  font-size: 14px;
  color: var(--white);
  font-weight: 500;
}

.edit-loc-add-button:hover {
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-width: 1px;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
