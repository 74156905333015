@import url("../../../App.css");

.up-select-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.up-bold-label {
  font-weight: 600;
}

.user-image-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1em;
}

.user-image-container {
  width: 120px;
  height: 120px;
}

#upload-user-photo {
  display: none;
}

.up-form-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  margin-top: 1.7rem;
  background-color: #f4f4f4;
  padding: 1rem 1.2rem;
  border-radius: 4px;
}

@media (max-width: 700px) {
  .up-form-content {
    width: 100%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .up-form-content {
    width: 95%;
  }
}
