@import url("../../..//App.css");

.cp-name-label {
  font-size: 20px;
  color: var(--pastel-003);
  text-align: center;
  margin: 20px;
}

.cp-email-label {
  font-size: 15px;
  color: var(--black);
}

.cp-points-value {
  font-size: 24px;
  color: var(--pastel-004);
  font-weight: 500;
}
