@import url("../../../App.css");

.sbmu-contentArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.sbmu-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-top: 5px;
  margin-bottom: 5px;
}

.download-link-btn-container {
  text-align: center;
  vertical-align: middle;
}

.download-link-btn {
  width: 45%;
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
  border-style: solid;
  font-size: 16px;

  height: 45px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.download-link-btn:hover {
  color: var(--white);
  background-color: var(--pastel-002);
}

.sbmu-icon-container {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

@media (max-width: 700px) {
  .download-link-btn {
    font-size: 12px;
  }
  .inverted-btn {
    font-size: 14px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .download-link-btn {
    font-size: 14px;
  }
}
