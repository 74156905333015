@import url("../../../App.css");
.ss-contentAreaSignIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ss-sign-in-screen-label {
  font-size: 26px;
  font-weight: 600;
  color: var(--red);
  margin-top: 1.5em;
}

.ss-sign-in-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.ss-sign-in-img {
  height: 100%;
  width: 100%;
}

.ss-sign-in-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4em;
}

.ss-sign-in-other-opt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 14px;
}

.ss-sign-in-col-button-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-forgot-password-label {
  color: var(--orange);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}

.ss-sign-in-input {
  font-size: 14px;
}

@media (max-width: 700px) {
  .ss-sign-in-img-contain {
    width: 40%;
    height: 40%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .ss-sign-in-img-contain {
    width: 40%;
    height: 40%;
  }
}
