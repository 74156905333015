@import url("../../../../App.css");
.contentAreaLDTD {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ldtd-screen-label {
  font-size: 2.4em;
  color: var(--pastel-004);
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ldtd-rest-label {
  font-size: 4em;
  color: var(--pastel-004);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ldtd-loc-label {
  font-size: 1.2em;
  color: var(--pastel-004);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ldtd-button-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 2rem;
  margin-right: 1.5rem;
  display: flex;
  flex-direction: row-reverse;
}

.ldtd-button {
  width: 20%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
  border-color: var(--red);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.ldtd-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.ldtd-list-remote-video-player-container {
  border: 1px solid #cddfe7;
  width: 100%;
  height: 80%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 2rem;
  z-index: 1;
}

.ldtd-list-remote-video-player {
  width: 100%;
  height: 100%;
}

.ldtd-list-remote-video-player.cover {
  object-fit: cover;
}

.ldtd-list-video-player {
  position: absolute;
  border: 1px solid #cddfe7;
  bottom: 2rem;
  left: 1.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 150px;
}

@media (max-width: 700px) {
  .ldtd-screen-label {
    font-size: 1.7em;
  }

  .ldtd-rest-label {
    font-size: 2.6em;
  }

  .ldtd-button {
    width: 30%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .ldtd-screen-label {
    font-size: 2em;
  }
  .ldtd-rest-label {
    font-size: 3.6em;
  }
  .ldtd-button {
    width: 30%;
  }
}
