@import url("../../../App.css");

.home-logo-container {
  width: 12%;
  height: 5%;
}

.nav-btn {
  color: var(--pastel-002);
}

.nav-btn:hover {
  color: var(--pastel-002);
}

@media (max-width: 700px) {
  .home-logo-container {
    height: 15%;
    width: 30%;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
  .home-logo-container {
    width: 25%;
    height: 10%;
  }
}
