@import url("../../../../App.css");

.inv-pd-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ipd-product-label {
  align-self: center;
  font-size: 2rem;
  font-weight: 600;
  color: var(--black);
}

.inv-pd-active {
  align-self: flex-end;
  width: fit-content;
  padding: 3px 30px;
  background-color: var(--btn-green);
  color: var(--white);
  border-radius: 5px;
  font-size: 12.9px;
}

.inv-pd-in-active {
  align-self: flex-end;
  width: fit-content;
  padding: 3px 30px;
  background-color: var(--red-error);
  color: var(--white);
  border-radius: 5px;
  font-size: 12.9px;
}

.ipd-product-item-val {
  font-size: 16px;
  color: var(--black);
}

.ipd-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ipd-row-item {
  font-size: 15px;
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  width: 50%;
}

.ipd-consumption-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  border-width: 0.75px;
  border-color: var(--gray);
  border-style: solid;
  margin-bottom: 5px;
  padding: 7px;
  overflow-x: scroll;
}

.ipd-date-text {
  align-self: flex-end;
  font-size: 12.9px;
  color: var(--gray);
}

.ipd-content-text {
  font-size: 16px;
  color: var(--pastel-004);
}

.ipd-quantity-label {
  font-size: 17px;
  color: var(--black);
  font-weight: 600;
}
