@import url("../../../../App.css");

.add-ep-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #e6e6e6;
}

.ep-input {
  width: 100%;
  font-size: 15px;
  margin-bottom: 1em;
}

.ep-row-input {
  width: 49%;
}

.ep-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
}

.ep-select {
  font-size: 14px;
  width: 100%;
  margin-bottom: 1em;
}

.ep-select-label {
  font-size: 14px;
  width: 49%;
}

.ep-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.ep-select-no-margin {
  margin-bottom: 0px;
}

.ep-input-row-wrap {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.ep-check-box {
  width: 30%;
}

.ep-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.ep-input-text {
  font-size: 15px;
  color: black;
}

.ep-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
  margin-top: 1.2em;
}

.ep-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
  font-size: 14px;
}

.ep-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.ep-option-with-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ep-remove-image-container {
  width: 25px;
  height: 25px;
  margin-left: 15px;
}

.ep-item-remove-container {
  align-self: flex-end;
  font-size: 13px;
  color: var(--red-error);
  background-color: var(--white);
  border-style: solid;
  border-radius: 4px;
  margin-top: 5px;
  border-width: 0.75px;
  border-color: var(--red-error);
  padding: 2px 10px;
  cursor: pointer;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
