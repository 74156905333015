@import url("../../../App.css");

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem 4rem;
  text-align: center;
}

.about-main-label {
  color: var(--black);
  font-weight: 700;
  font-size: 1.8rem;
}

.about-main-label-sub {
  color: var(--gray);
  font-weight: 300;
  font-size: 1.8rem;
}

@media (max-width: 700px) {
  .about-main-label {
    font-size: 1.2rem;
  }

  .about-main-label-sub {
    font-size: 1.2rem;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
}
