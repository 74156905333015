@import url("../../../App.css");

.srqtl-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.srqtl-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.add-srqtl-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.srqtl-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.srqtl-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.srqtl-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.srqtl-list-select {
  font-size: 13px;
  width: 100%;
}

.srqtl-input {
  width: 100%;
}

.srqtl-input-text {
  font-size: 13px;
  color: black;
}

.srqtl-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.srqtl-row-margin {
  margin-top: 2px;
  margin-bottom: 2px;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
