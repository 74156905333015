@import url("../../../App.css");

.search-container {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-bar {
  font-size: 16px;
  color: var(--black);
  padding: 10px 15px;
  flex-grow: 2;
}

.click-here-label {
  color: var(--pastel-004);
  font-weight: 300;
  font-size: 1.8rem;
  text-decoration: underline;
  cursor: pointer;
}

.search-icon-container {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}

.fr-error-lable {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 16px;
  color: var(--black);
}

.fr-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  margin: 2px 1.2rem 5px 1.2rem;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--pastel-002);
  border-radius: 10px;
}

.fr-title {
  font-size: 1.7rem;
  color: var(--pastel-004);
  font-weight: 600;
}

.fr-description {
  font-size: 20px;
  color: var(--black);
}

.fr-count-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.fr-vote-label {
  font-size: 16px;
  color: var(--pastel-003);
}

.fr-date-label {
  align-self: flex-end;
  color: var(--gray);
  font-size: 14px;
}

.fr-comment-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fr-btn-label {
  font-size: 16px;
  color: var(--pastel-002);
  cursor: pointer;
}

.fr-btn-label:hover {
  font-weight: 600;
}

@media (max-width: 700px) {
  .click-here-label {
    font-size: 1.4rem;
  }

  .fr-title {
    font-size: 1.5rem;
  }

  .fr-description {
    font-size: 18px;
  }

  .fr-vote-label {
    font-size: 15px;
  }

  .fr-date-label {
    font-size: 13px;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
  .fr-title {
    font-size: 1.1rem;
  }

  .fr-description {
    font-size: 16px;
  }

  .fr-vote-label {
    font-size: 14px;
  }

  .fr-date-label {
    font-size: 12px;
  }
}
