@import url("../../../App.css");

.plo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.plo-btn {
  color: var(--white);
  background-color: var(--red);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--red);
}

.plo-btn:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.plo-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.plo-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.plo-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.plo-list-select {
  font-size: 13px;
  width: 100%;
}

.plo-cat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 5px;
}

.plo-cat-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: var(--red);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  color: var(--red);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 5px;
  cursor: pointer;
}

.plo-table-col-title {
  color: var(--pastel-004);
  font-size: 16px;
}

.plo-table-col-item {
  color: var(--black);
  font-size: 14px;
}

.plo-table-row-container {
  cursor: pointer;
}

.plo-button-row {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3em;
}

.plo-button {
  width: 40%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
  border-color: var(--red);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.plo-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.plo-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.plo-cart-label {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--black);
  text-align: center;
}

.plo-row-item-container {
  width: 90%;
}

@media (max-width: 700px) {
  .plo-btn {
    width: 50%;
  }

  .plo-button {
    font-size: 14px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .plo-button {
    font-size: 14px;
  }
}
