@import url("../../App.css");

.color-form-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  margin-top: 5px;
  background-color: var(--light-yellow-color);
  padding: 1rem 1.2rem;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .color-form-content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .color-form-content {
    width: 90%;
  }
}
