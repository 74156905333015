@import url("../../App.css");

.tc-a-link {
  text-decoration: underline;
  color: var(--orange);
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.tc-text {
  font-size: 16px;
  color: var(--black);
  padding-left: 15px;
  padding-right: 15px;
}

.tc-bottom-margin {
  padding-bottom: 20px;
}

.tc-number-label {
  font-size: 17px;
  font-weight: 600;
  color: var(--black);
}

.tc-sub-heading {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

.tc-heading {
  font-size: 2.1rem;
  color: var(--orange);
  padding-left: 15px;
}

@media (min-width: 701px) and (max-width: 900px) {
  .tc-heading {
    font-size: 1.4rem;
  }
}
