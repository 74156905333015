.cp-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100vh;
}

.cp-label {
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 10px;
}

.cp-updateLabel {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
}

.cp-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.cp-text-zero-margin {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
}

.cp-text-black {
  color: #000000;
}

.cp-text-small-bold {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
}

.cp-first-inline {
  margin-left: 12px;
}

.cp-second-inline {
  margin-left: 24px;
}

.cp-text-span-bold {
  font-size: 16px;
  font-weight: bold;
}
