body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Hind Siliguri", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Hind Siliguri",
    "sans-serif";
}

::-webkit-scrollbar {
  display: none;
}
