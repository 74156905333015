@import url("../../../../App.css");

.ols-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.ols-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ols-refresh-container {
  height: 25px;
  padding-bottom: 4px;
  border-color: var(--pastel-004);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 35%;
  align-items: center;
  cursor: pointer;
}

.ols-img {
  width: 100%;
  height: 100%;
}

.ols-img-container {
  width: 20px;
  height: 20px;
}

.ols-refresh-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--pastel-004);
  margin-top: 5px;
  margin-left: 5px;
}

.add-ols-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.add-ols-btn {
  color: var(--white);
  background-color: var(--pastel-004);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-004);
}

.add-ols-btn:hover {
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-width: 1px;
}

.ols-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
}

.ols-card {
  width: 47%;
  border-color: var(--pastel-004);
  border-width: 1px;
  padding: 7px;
  margin-right: 5px;
}

.ols-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.ols-card-title-light {
  font-size: 15px;
  color: var(--pastel-004);
  margin-top: 10px;
}

.ols-card-label {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
}
.ols-card-label-small {
  font-size: 13px;
  color: var(--black);
}

.ols-card-label-grow {
  flex-grow: 2;
}

.ols-card-label-name-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 13px;
  color: var(--black);
  font-weight: 500;
}

.ols-card-label-name-container-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 13px;
  color: var(--black);
  font-weight: 500;
  height: 35px;
  overflow-y: scroll;
}

.ols-card-label-blue {
  font-size: 14px;
  color: var(--pastel-004);
  font-weight: 500;
}

.ols-card-label-blue-bold {
  font-size: 13px;
  font-weight: 600;
}

.ols-card-label-val {
  font-size: 14px;
  color: var(--black);
}

.ols-card-row-item-border {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: #aeaeae;
  border-width: 0.5px;
  border-style: solid;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 50%;
}

.ols-card-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.ols-card-btn-row-border {
  border: var(--gray);
  border-width: 0.75px;
  border-style: solid;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.ols-card-btn-row-btn {
  justify-content: space-between;
}
.h-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.ols-card-btn {
  color: var(--white);
  background-color: var(--pastel-002);
  font-size: 13px;
  width: 45%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-002);
}

.ols-card-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-color: var(--pastel-002);
}

.ols-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.ols-input {
  width: 47%;
  font-size: 14px;
}

.ols-warning-label {
  font-size: 12px;
  color: var(--red);
}

.ols-refund-label {
  font-size: 14px;
  color: var(--red);
  margin-top: 5px;
  margin-bottom: 5px;
}

.ols-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ols-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.ols-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.ols-list-select {
  font-size: 13px;
  width: 100%;
}

.ols-not-available {
  font-size: 14px;
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--pastel-002);
  padding: 2px 10px;
}

.ols-not-available-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ols-close-img-container-close {
  width: 18px;
  height: 18px;
  align-self: center;
}

.ols-customization-label {
  font-size: 10px;
  font-weight: 600;
  color: var(--gray);
}

.ols-customization-item {
  font-size: 11px;
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--gray);
  margin-right: 4px;
  margin-top: 2px;
  padding: 2px 7px;
}

.ols-table-row-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
}

.ols-order-status-label-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ols-order-status-label {
  font-size: 16px;
  color: var(--black);
  margin-left: 10px;
}

.ols-button-right-align {
  align-self: flex-end;
}

.fab-container {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin-right: 20px;
  margin-bottom: 5rem;
  background-color: var(--orange);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 3px 6px #e2e2e2;
}

@media (max-width: 700px) {
  .add-ols-btn {
    width: 50%;
  }

  .ols-card {
    width: 95%;
    margin-bottom: 5px;
  }

  .ols-card-btn {
    width: 60%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .add-ols-btn {
    width: 50%;
  }
}
