@import url("../../../../../App.css");

.option-price-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.option-price-field-container {
  flex: 1;
}

.option-price-list-container {
  display: flex;
  flex-direction: row;
  margin: 5px;
  flex-wrap: wrap;
}

.option-price-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-color: var(--pastel-003);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  color: var(--pastel-003);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 3px 5px;
}

.option-price-list-row-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: var(--pastel-003);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 3px 5px;
  cursor: pointer;
}

.option-price-list-row-item-text {
  font-size: 14px;
  color: var(--pastel-003);
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
