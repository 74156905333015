@import url("../../../App.css");

.rqtl-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.rqtl-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rqtl-refresh-container {
  height: 25px;
  padding-bottom: 4px;
  border-color: var(--pastel-004);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 35%;
  align-items: center;
  cursor: pointer;
}

.rqtl-img {
  width: 100%;
  height: 100%;
}

.rqtl-img-container {
  width: 20px;
  height: 20px;
}

.rqtl-refresh-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--pastel-004);
  margin-top: 5px;
  margin-left: 5px;
}

.add-rqtl-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.rqtl-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rqtl-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.rqtl-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.rqtl-list-select {
  font-size: 13px;
  width: 100%;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
