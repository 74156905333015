@import url("../../../App.css");

.mpc-content-container {
  padding: 1em 1.2em;
}

.mpc-image-container {
  width: 100%;
  height: 220px;
  align-self: center;
}

.mpc-img-container-color {
  width: 100%;
  height: 100%;
  background-color: #ffb90f;
  border-radius: 5px;
}

.mpc-title {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--pastel-004);
}

.mpc-description {
  font-size: 1.2rem;
  color: var(--gray);
}

.mpc-center-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mpc-quantity-img-container {
  width: 32px;
  height: 32px;
}

.mpc-div-justify-space {
  justify-content: space-between;
}

.mpc-selected-img-container {
  width: 20px;
  height: 20px;
}

.mpc-quantity-val {
  font-size: 1.5em;
  margin-left: 15px;
  margin-right: 15px;
  color: var(--black);
}

.mpc-option-item-name {
  font-size: 15px;
  font-weight: 600;
  color: var(--pastel-002);
}

.mpc-option-item-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.mpc-option-type-description {
  font-size: 12px;
  color: var(--gray);
  align-self: flex-end;
}

.mpc-add-btn {
  font-size: 14px;
  color: var(--green-success);
  background-color: var(--white);
  padding: 0px 20px;
  align-self: center;
  border-radius: 4px;
  margin-top: 5px;
  border-color: var(--green-success);
  border-width: 0.75px;
  border-style: solid;
  cursor: pointer;
}

.mpc-option-type-container {
  font-size: 14px;
  color: var(--pastel-003);
  padding: 0px 20px;
  background-color: var(--white);
  border-radius: 2px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--pastel-003);
  align-self: flex-end;
  margin-top: 7px;
  margin-bottom: 5px;
}
