@import url("../../../../App.css");

.cud-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.cud-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.cud-row-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--pastel-002);
}

.cud-field-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--pastel-004);
  margin-top: 1.5rem;
}

.cud-field-label-margin-one {
  margin-top: 10px;
}

.cud-message {
  font-size: 18px;
  color: var(--black);
  margin-top: 5px;
}

.add-update-label {
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 5px;
  font-weight: 700;
  color: var(--pastel-003);
}
