@import url("../../../../App.css");

.ioid-image-overlay-container {
  width: 80%;
  height: 80%;
}

.ioid-image-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;
  margin-top: 10px;
}

.ioid-image-row-item {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
