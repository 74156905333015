@import url("../../../../App.css");

.incl-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.incl-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.incl-table-col-title {
  color: var(--pastel-004);
  font-size: 16px;
}

.incl-table-row-container {
  cursor: pointer;
}

.incl-table-col-item {
  color: var(--black);
  font-size: 14px;
}

.incl-btn-container {
  font-size: 13.5px;
  color: var(--pastel-002);
  border-radius: 4px;
  border-width: 0.75px;
  border-color: var(--pastel-002);
  border-style: solid;
  padding: 2px 7px;
  cursor: pointer;
}
