@import url("../../App.css");
.contentAreaHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-logo-container {
  width: 12%;
  height: 5%;
}

.footer-logo-container {
  height: 50%;
}

.img-container {
  height: 70%;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.header-img-container {
  height: 70%;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1.5em;
}

.brand-name {
  font-size: 30px;
  font-weight: 600;
  color: var(--red);
  background-color: var(--white);
  text-decoration: none;
}

.brand-name:hover {
  color: var(--red);
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-loginButton {
  margin-left: 10px;
  width: 100px;
  font-size: 16px;
  background-color: var(--pastel-002);
  color: var(--white);
}

.h-loginButton:hover {
  background-color: var(--white);
  color: var(--pastel-002);
  border-color: var(--pastel-002);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
}

.h-createAccount {
  font-size: 16px;
  background-color: transparent;
  color: var(--pastel-002);
}

.h-createAccount:hover {
  background-color: var(--pastel-002);
  color: var(--white);
  border-color: var(--pastel-002);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
}

.home-step-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap;
}

.step-img-container {
  width: 260px;
  height: 230px;
  border-color: var(--pastel-002);
  border-radius: 5px;
  border-style: solid;
  border-width: 0.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 5px;
  margin-top: 5px;
}

.step-text-container {
  width: 260px;
  height: 130px;
  color: var(--black);
  border-color: var(--pastel-002);
  border-radius: 5px;
  border-style: solid;
  border-width: 0.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.screenFooterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.colElement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.colElementCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.colElementHeading {
  font-size: 16px;
  align-self: flex-start;
  margin-bottom: 10px;
  color: var(--pastel-004);
  font-weight: 600;
}

.colElementHeadingLogo {
  font-size: 16px;
  align-self: center;
  margin-bottom: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.colElementHeadingLogoLocation {
  font-size: 16px;
  align-self: center;
  margin-bottom: 5px;
  margin-top: 10px;
  color: var(--pastel-004);
  font-weight: 600;
}

.colElement14Label {
  font-size: 15px;
  color: var(--black);
}

.colElement15Label {
  font-size: 15px;
  color: var(--black);
}

.labelBlackBold {
  color: var(--black);
  font-weight: 600;
}
.footerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: beige;
  margin-top: 20px;
  cursor: pointer;
}

.footerElem {
  font-size: 13px;
  color: var(--pastel-004);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.policy-container {
  align-self: flex-end;
  display: flex;
  font-size: 13px;
  color: var(--pastel-004);
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.margin-top {
  margin-top: 10px;
}

.reasons-heading {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  color: var(--pastel-004);
  font-weight: 600;
  align-self: center;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.credit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 7px;
  margin-top: 1.5rem;
}

.flag-container {
  width: 28px;
  margin-left: 15px;
}

.h-sub-link-text {
  font-size: 15px;
  color: var(--black);
}

@media (max-width: 700px) {
  .home-logo-container {
    height: 15%;
    width: 30%;
  }

  .h-loginButton {
    font-size: 13px;
  }

  .h-createAccount {
    font-size: 13px;
  }

  .header-img-container {
    height: 40%;
  }

  .reasons-heading {
    font-size: 1.2em;
  }

  .colElementCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .colElementHeadingLogo {
    align-self: flex-start;
    margin-top: 1.2rem;
  }

  .colElementHeadingLogoLocation {
    align-self: flex-start;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
  .home-logo-container {
    width: 25%;
    height: 10%;
  }

  .h-loginButton {
    font-size: 15px;
  }

  .h-createAccount {
    font-size: 15px;
  }

  .header-img-container {
    height: 60%;
  }

  .reasons-heading {
    font-size: 1.7em;
  }
}
