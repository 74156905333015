@import url("../../../App.css");
.pl-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pl-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.pl-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-color: var(--pastel-001);
  background-color: #fbfbfb;
  border-width: 0.5px;
  border-style: solid;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.pl-column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  flex-grow: 2;
}

.pl-img-container {
  width: 10%;
  height: 5%;
}

.pl-logo-container {
  width: 15%;
  height: 15%;
}

.pl-icon-container {
  width: 3%;
  height: 3%;
}

.pl-img {
  width: 100%;
  height: 100%;
}

.pl-card-title {
  font-size: 17px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.pl-card-description {
  font-size: 13px;
  color: var(--pastel-004);
}

.pl-price-label {
  font-size: 16px;
  color: var(--pastel-003);
  font-weight: 600;
  margin-top: 2px;
}

.pl-price-label-striked {
  font-size: 15px;
  color: var(--pastel-002);
  font-weight: 600;
  margin-top: 2px;
  text-decoration: line-through;
}

.pl-btn-container {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  background-color: var(--red);
  color: var(--white);
  width: 40%;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 2px;
}

.pl-review-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
}

.pl-btn {
  width: 40%;
  background-color: var(--pastel-002);
  font-size: 13px;
  color: var(--white);
  border-color: var(--pastel-002);
  border-style: solid;
  border-width: 0.75px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 2px;
  font-weight: 500;
  align-self: flex-end;
}

.pl-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.mp-row-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.mp-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
  margin-top: 5px;
}

.mp-check-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.mp-input-text {
  font-size: 13px;
  color: black;
  align-self: center;
}

.mp-review-rating-label {
  font-size: 14px;
  color: var(--pastel-002);
  font-weight: 500;
  cursor: pointer;
}

.mp-review-no-rating-label {
  font-size: 14px;
  color: var(--gray);
  font-weight: 500;
  cursor: none;
}

.mp-modal {
  position: absolute;
  top: 15%;
  left: 5%;
  right: 5%;
  bottom: 15%;
  background-color: var(--white);
  border-radius: 4px;
  padding: 10px;
  overflow: scroll;
}

.mp-modal-overlay {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mp-modal-container {
  display: flex;
  flex-direction: column;
}

.mp-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.mp-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.mp-review-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  border-color: var(--gray);
  border-width: 0.1px;
  border-style: solid;
  margin: 5px;
  padding: 4px;
  border-radius: 4px;
}

.mp-review-text {
  font-size: 16px;
  color: var(--pastel-004);
  margin-top: 5px;
}

.mp-review-user-text {
  font-size: 14px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 5px;
  margin-right: 10px;
  align-self: flex-end;
}

.no-product-label {
  font-size: 16px;
  color: var(--pastel-004);
  text-align: center;
  padding-top: 5rem;
  font-weight: 600;
}

.out-of-stock {
  font-size: 14px;
  color: var(--red);
  margin-top: 5px;
  font-weight: 500;
}

.mp-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

@media (max-width: 700px) {
  .pl-logo-container {
    width: 25%;
    height: 25%;
  }

  .pl-img-container {
    width: 20%;
  }

  .pl-icon-container {
    width: 7%;
    height: 7%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .pl-logo-container {
    width: 20%;
    height: 20%;
  }

  .pl-img-container {
    width: 15%;
  }

  .pl-icon-container {
    width: 5%;
    height: 5%;
  }
}
