@import url("../../../../App.css");

.adt-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.adt-input {
  width: 100%;
  margin-bottom: 1.2em;
}

.adt-input-text {
  font-size: 13px;
  color: black;
}

.adt-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.adt-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.adt-button {
  width: 40%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.adt-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.adt-select {
  width: 100%;
}

.adt-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: bold;
}

.adt-text {
  font-size: 14px;
  color: black;
}

@media (max-width: 700px) {
  .adt-button {
    font-size: 14px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .adt-button {
    font-size: 15px;
  }
}
