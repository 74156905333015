@import url("../../../App.css");

.screenFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 5px;
  background-color: var(--pastel-001);
  margin-top: 20px;
  cursor: pointer;
  width: 100%;
}

.colElementItemCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-logo-container {
  height: 50%;
}

.colElement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.colElementHeading {
  font-size: 16px;
  align-self: flex-start;
  margin-bottom: 10px;
  color: var(--pastel-004);
  font-weight: 600;
}

.h-sub-link-text {
  font-size: 15px;
  color: var(--black);
}

.colElementCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.colElementHeadingLogo {
  font-size: 16px;
  align-self: center;
  margin-bottom: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.colElement14Label {
  font-size: 15px;
  color: var(--black);
}

.colElementHeadingLogoLocation {
  font-size: 16px;
  align-self: center;
  margin-bottom: 5px;
  margin-top: 10px;
  color: var(--pastel-004);
  font-weight: 600;
}

.colElement15Label {
  font-size: 15px;
  color: var(--black);
}

.labelBlackBold {
  color: var(--black);
  font-weight: 600;
}

.credit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 7px;
  margin-top: 1.5rem;
}

.flag-container {
  width: 28px;
  margin-left: 15px;
}

.footerElem {
  font-size: 13px;
  color: var(--pastel-004);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 700px) {
  .colElementItemCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .colElementCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .colElementHeadingLogo {
    align-self: flex-start;
    margin-top: 1.2rem;
  }

  .colElementHeadingLogoLocation {
    align-self: flex-start;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
}
