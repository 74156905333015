@import url("../../../App.css");

.hero {
  background: url("../../../assets/restaurant1.jpg") no-repeat center
    center/cover;
  height: 100vh;
  position: relative;
  color: #fff;
}
.hero .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.hero .content h1 {
  font-size: 3.4em;
  padding: 0 2rem;
  font-weight: 600;
}

.hero .content p {
  font-size: 16px;
  padding: 0 10px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.hero * {
  z-index: 10;
}

.nav-item-label {
  cursor: pointer;
  color: var(--pastel-004);
}

.nav-item-label:hover {
  font-weight: 600;
}

.nav-item-label-btn {
  cursor: pointer;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--pastel-002);
  color: var(--pastel-002);
  text-align: center;
}

.nav-item-label-btn:hover {
  border-width: 2px;
  border-color: var(--pastel-002);
  font-weight: 600;
  color: var(--pastel-002);
}

.feature-section-container {
  padding: 1.5rem 1rem;
  background: #fef6e7;
}

.quote-section-container {
  padding: 1.5rem 1rem;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feature-label {
  font-size: 2rem;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 1rem;
}

.feature-sub-label {
  font-weight: normal;
  color: #b3b3b3;
}

.feature-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2rem;
}

.section-item-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
}

.section-item-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.food-vendor-label {
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 10px;
}

.food-vendor-item {
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--black);
  margin-bottom: 2px;
}

.h-line-gray {
  width: 60px;
  height: 2px;
  background: var(--gray);
  align-self: center;
  margin-left: 10px;
}

.feature-section-price-container {
  padding: 1.5rem 1rem;
  background: #fff;
}

.fspc-label {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
}

.pricing-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pricing-row-container-item {
  background-color: #fff;
  border-radius: 5px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--pastel-002);
  margin-right: 10px;
  margin-top: 5px;
  padding: 1.2rem 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 24%;
  height: 430px;
}

.pricing-label {
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #000;
  text-align: center;
  width: 100%;
}

.pricing-price-parent-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  margin: 1.4rem 0px;
}

.pricing-starting-label {
  font-size: 13.1px;
  font-weight: 800;
  color: #002e63;
  align-self: start;
}

.pricing-price-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.price-currency-label {
  font-size: 13.5px;
  color: #002e63;
}

.price-price-label {
  font-size: 3rem;
  color: #000;
  font-weight: 800;
  margin-top: 0px;
  padding-top: 0px;
}

.price-price-hourly {
  font-size: 13.5px;
  align-self: flex-end;
  color: #002e63;
}

.pricing-feature-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
}

.pricing-feature-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.pricing-feature-parent-customize-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.pricing-feature-check-container {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.pricing-feature-label {
  font-size: 16px;
  color: #000;
  text-align: start;
}

.pricing-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

@media (max-width: 700px) {
  .hero .content h1 {
    font-size: 1.7em;
  }

  .feature-label {
    font-size: 1.4rem;
  }

  .fspc-label {
    font-size: 1.4rem;
  }

  .pricing-row-container-item {
    width: 98%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .hero .content h1 {
    font-size: 3em;
  }

  .pricing-row-container-item {
    width: 45%;
  }
}
