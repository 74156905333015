@import url("../../../../App.css");

.aresv-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.aresv-input {
  width: 100%;
  margin-bottom: 1.2em;
}

.aresv-input-text {
  font-size: 13px;
  color: black;
}

.aresv-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.aresv-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.aresv-select {
  width: 100%;
}

.aresv-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: bold;
}

.aresv-text {
  font-size: 14px;
  color: black;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
