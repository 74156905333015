:root {
  --red: #dc3545;
  --white: #ffffff;
  --blue: #183766;
  --black: #000000;
  --orange: #f68c1d;
  --gray: #aeaeae;
  --green: #00c400;
  --yellow: #ffea00;
  --logo-red: #ee0000;
  --btn-green: #65c050;
  --green-success: #52c41a;
  --red-error: #ff4d4f;
  --orange-warning: #faad14;
  --blue-general: #1890ff;
  --pink: #f9eae8;
  --light-yellow-color: #f8fced;
  /* --pastel-001: #fef6ed;
  --pastel-002: #f68c1d;
  --pastel-003: #d06e08;
  --pastel-004: #500000; */
  --pastel-001: #f5ebdc;
  --pastel-002: #f58233;
  --pastel-003: #6f3c2f;
  --pastel-004: #323231;
  /* --pastel-001: #e4f9f5;
  --pastel-002: #30e3ca;
  --pastel-003: #11999e;
  --pastel-004: #063d3f; */
}

body {
  font-family: "Hind Siliguri", sans-serif;
  background-color: var(--white);
  scrollbar-width: 5px;
}

button:focus {
  border-color: var(--pastel-004) !important;
  box-shadow: 0 0 1px rgba(245, 130, 51, 1) !important;
}

input:focus {
  border-color: var(--pastel-004) !important;
  box-shadow: 0 0 1px rgba(245, 130, 51, 1) !important;
}

.table thead th {
  border-bottom: 2px solid var(--pastel-003);
}

.content-area {
  width: 100vw;
  overflow: scroll;
  height: 94vh;
}

.content-area-full {
  width: 100vw;
  overflow: scroll;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.navbar {
  border-bottom: solid 0.75px #ebebeb;
}
.bottom-tab-nav {
  border-top: solid 0.75px #ebebeb;
  background-color: var(--white);
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
  background-color: var(--white);
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}
.icon-container {
  width: 20px;
  height: 20px;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tab-color-active {
  color: var(--pastel-002);
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
}

.goToCartContainer {
  background-color: var(--pastel-001);
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  bottom: 0;
  margin-bottom: 10vh;
  width: 98vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cartItemLabel {
  font-size: 15px;
  color: var(--pastel-004);
  margin-top: 7px;
  margin-bottom: 5px;
  font-weight: 600;
}

.goToCartContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.goToCartBtn {
  background-color: var(--pastel-002);
  font-size: 16px;
  color: var(--white);
  padding-left: 40px;
  padding-right: 40px;
  height: 35px;
  font-weight: 500;
}

.goToCartBtn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.cartCloseContainerImg {
  width: 15px;
  height: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.consentContainer {
  background-color: var(--pastel-003);
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
  margin-bottom: 10vh;
  width: 90vw;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.consentText {
  font-size: 14px;
  color: var(--white);
  text-align: center;
  width: 70vw;
  padding-left: 10px;
}

.consentCloseContainer {
  width: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 15px;
}

.consentClickLabel {
  text-decoration: underline;
  font-size: 15px;
  color: var(--pastel-001);
  cursor: pointer;
}

.consentCloseContainerImg {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.close-img {
  width: 100%;
  height: 100%;
}

.d-cr-modal {
  position: absolute;
  top: 35%;
  left: 20%;
  right: 5%;
  bottom: 30%;
  background-color: var(--white);
  border-radius: 4px;
  padding: 10px;
}

.label-bold {
  font-weight: 600;
}

.align-left {
  align-self: flex-start;
}

.table-scroll-container {
  width: 100%;
  overflow: scroll;
}

.content-error {
  font-size: 16px;
  text-align: center;
  margin: 20px 0px;
  color: var(--black);
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.demo-link-container {
  position: fixed;
  top: 65%;
  left: 0;
  transform: translateY(-50%);
  height: 80px;
  width: 60px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  color: var(--white);
  padding: 0px 10px;
  background-color: var(--pastel-002);
  z-index: 5;
  cursor: pointer;
}

th,
td {
  white-space: nowrap;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.select-full-width {
  width: 100%;
  font-size: 15px;
  height: 50px;
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--gray);
  margin-bottom: 10px;
  z-index: 2;
}

.select-full-width-no-border {
  width: 100%;
  font-size: 15px;
  height: 50px;
  z-index: 2;
}

.react-select__menu-list {
  z-index: 5;
}

.select-half-width {
  width: 49%;
  font-size: 14px;
  z-index: 2;
}

.select-option-label {
  font-size: 15px;
  color: var(--black);
  padding: 10px 10px;
}

.up-bold-label {
  font-weight: 600;
}
