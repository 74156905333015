@import url("../../App.css");

.common-form {
  width: 80%;
  align-self: center;
  margin-top: 1.5rem;
}

@media (max-width: 700px) {
  .common-form {
    width: 95%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .common-form {
    width: 90%;
  }
}
