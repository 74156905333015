.cr-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.cr-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.cr-content-container {
  display: flex;
  flex-direction: column;
}

.cr-logo-container {
  width: 15%;
  height: 15%;
}

.cr-img {
  width: 100%;
  height: 100%;
}

.add-cr-row {
  display: flex;
  flex-direction: column;
  border-color: var(--pastel-001);
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.cr-item-label {
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.cr-amount-label {
  font-size: 14px;
  margin-top: 2px;
  color: var(--pastel-004);
}

.cr-amount-label-light {
  font-size: 14px;
  margin-top: 2px;
  color: var(--gray);
}

.cr-qty-update-label {
  color: var(--pastel-004);
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  align-self: flex-end;
}

.add-cr-btn-inverted {
  margin-top: 15px;
  align-self: flex-end;
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.add-cr-btn {
  margin-top: 15px;
  align-self: flex-end;
  color: var(--white);
  background-color: var(--pastel-002);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--pastel-002);
}

.add-cr-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.add-cr-btn-inverted:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.add-c-modal {
  position: absolute;
  top: 22%;
  left: 5%;
  right: 5%;
  bottom: 22%;
  background-color: var(--white);
  border-radius: 4px;
  padding: 10px;
}

.add-c-modal-overlay {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.add-c-modal-container {
  display: flex;
  flex-direction: column;
}

.add-c-modal-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.add-cr-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-cr-modal-row-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  border: 0.75px;
  border-color: var(--gray);
  border-style: solid;
  width: 50%;
  font-size: 14px;
}

.add-cr-modal-row-item-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 5px;
  border: 0.75px;
  border-color: var(--gray);
  border-style: solid;
  width: 50%;
  padding-right: 10px;
  font-size: 14px;
}

.cr-select {
  font-size: 14px;
  width: 100%;
}

.add-c-img-container {
  width: 24px;
  height: 24px;
}

.add-c-img {
  width: 100%;
  height: 100%;
}

.cr-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cr-modal-img-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 130px;
  margin-top: 10px;
}

.cr-modal-order-success {
  font-size: 1.7em;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 15px;
}

.cr-modal-order-subtext {
  font-size: 16px;
  color: var(--pastel-004);
  margin-top: 10px;
  text-align: center;
}

.cr-modal-order-number {
  font-size: 16px;
  color: var(--orange);
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
}
.cr-modal-order-number-val {
  margin-top: 0px;
}

.cr-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.cr-close-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 5px;
  background-color: var(--pastel-002);
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--white);
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}

.cr-payment-mode-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cr-payment-mode-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cr-payment-mode-label {
  font-size: 16px;
  color: var(--black);
  margin-left: 10px;
  font-weight: 600;
}

.cr-payment-mode-label-light {
  font-size: 16px;
  color: var(--black);
  margin-left: 10px;
}

.cr-payment-mode-sub-label {
  font-size: 14px;
  color: var(--black);
  margin-left: 10px;
}

.cr-customer-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cr-print-btn-container {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cr-print-btn {
  background-color: var(--orange);
  color: var(--white);
  font-size: 15px;
  width: fit-content;
  padding: 4px 20px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
}

.cr-print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cr-print-row-parent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.cr-print-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cr-print-col-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: end;
  align-items: end;
  padding-right: 10px;
}

.cr-print-row-heading {
  font-weight: 600;
}

.cr-print-row-label {
  width: 60%;
  text-align: left;
  font-size: 15px;
  color: #000;
}

.cr-print-row-qty {
  width: 10%;
  text-align: center;
  font-size: 15px;
  color: #000;
}

.cr-print-row-price {
  width: 30%;
  text-align: right;
  font-size: 15px;
  color: #000;
}

.cr-restaurant-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  text-align: center;
}

.cr-location-label {
  font-size: 14px;
  color: var(--black);
  text-align: center;
}

.cr-date-label {
  font-size: 13.5px;
  color: #717171;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (min-width: 701px) and (max-width: 900px) {
  .cr-logo-container {
    width: 20%;
    height: 20%;
  }

  .add-c-modal {
    top: 30%;
    bottom: 30%;
  }

  .add-cr-btn {
    width: 50%;
  }

  .add-cr-btn-inverted {
    width: 50%;
  }
}

@media (max-width: 700px) {
  .cr-logo-container {
    width: 30%;
    height: 30%;
  }

  /* .add-cr-row {
    margin-left: 0%;
    margin-right: 0%;
  } */

  .add-cr-btn {
    width: 50%;
  }

  .add-cr-btn-inverted {
    width: 50%;
  }
}

@media (max-width: 660px) {
  .cr-modal-order-success {
    font-size: 1.2em;
  }

  .cr-modal-order-subtext {
    font-size: 14px;
  }

  .cr-modal-order-number {
    font-size: 14px;
  }
}
