@import url("../../App.css");

.common-input-half {
  width: 49%;
  font-size: 14px;
  color: var(--black);
  margin-bottom: 10px;
}

.common-input-half-float {
  font-size: 16px;
  color: var(--black);
  background-color: var(--white);
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: #9e9e9e !important;
  font-size: 15px;
  background-color: var(--white);
  color: var(--black);
} */
