@import url('../../App.css');

$sidebar-bg-color: var(--pastel-001) !default;
$sidebar-color: var(--white) !default;
$sidebar-width: 250px !default;
$sidebar-collapsed-width: 65px !default;
$highlight-color: var(--white) !default;
$submenu-bg-color: var(--pastel-002) !default;
$submenu-bg-color-collapsed: var(--white) !default;
$icon-bg-color: var(--pastel-002) !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

// @import "~react-pro-sidebar/dist/scss/styles.scss";

.headerContainer {
  padding-left: 10px;
  padding-right: 10px;
  display: "flex";
  flex-direction: "column";

  .toggle-container {
    display: flex;
    justify-content: center;
    margin-top: 5px;

    .toggle {
      width: 30px;
      height: 30px;

      .img {
        width: "100%";
        height: "100%";
      }
    }
  }
  
  .toggle-container-end {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;

    .toggle {
      width: 30px;
      height: 30px;

      .img {
        width: "100%";
        height: "100%";
      }
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-container {
      // width: 100px;
      height: 130px;
    }

    .img-container-collapse {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
      margin-top: 5px;
    }

    .img {
      height: "100%";
      width: "100%";
    }
  }
}

.menu-item-label{
  color: #382f1e;
  font-size: 15px;
  font-weight: 600;
}

.footer-text {
  font-size: 12px;
  color: var(--pastel-004);
}
