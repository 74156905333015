@import url("../../../App.css");

.section-item-partner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-item-partner-label {
  display: flex;
  flex-direction: row;
}

.h-line {
  width: 30px;
  height: 2px;
  background: var(--black);
  align-self: center;
  margin-left: 10px;
}

.section-partner-with-us {
  height: 330px;
}

.section-partner-label {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--orange);
}

.section-partner-label-one {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--black);
}

.section-partner-label-two {
  font-weight: 300;
  color: var(--gray);
}

@media (max-width: 700px) {
  .section-partner-label {
    font-size: 0.9rem;
  }

  .section-partner-label-one {
    font-size: 1.3rem;
  }
}
@media (min-width: 701px) and (max-width: 900px) {
}
