@import url("../../App.css");

.screen-label {
  font-size: 3.4em;
  color: var(--pastel-004);
}

@media (max-width: 700px) {
  .screen-label {
    font-size: 1.7em;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .screen-label {
    font-size: 3em;
  }
}
