@import url("../../../App.css");

.col-vb-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.col-vb-data-container {
  display: flex;
  flex-direction: column;
}

.col-vb-screen-label {
  font-size: 3.4em;
  color: var(--pastel-004);
}

.col-vb-item-label {
  font-size: 15px;
  margin-top: 5px;
  color: var(--pastel-004);
  font-weight: 600;
}

.col-vb-item-label-container {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.col-vb-amount-label {
  font-size: 14px;
  margin-top: 2px;
  color: var(--pastel-004);
}

.add-col-vb-row {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border-radius: 4px;
  border-color: var(--gray);
  border-style: solid;
  border-width: 0.75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20%;
  margin-right: 20%;
}

.col-vb-item-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}
.col-vb-item-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: var(--gray);
  padding-top: 3px;
  padding-bottom: 3px;
  border-width: 0.75px;
  width: 50%;
  color: var(--black);
}

.col-vb-price-container {
  margin-top: 5px;
  color: var(--black);
  font-size: 16px;
  display: flex;
  flex-direction: row-reverse;
}

.col-vb-price-container-gt {
  font-size: 17px;
  font-weight: 600;
}

.col-vb-status-container {
  align-self: flex-end;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 50%;
  background-color: var(--red);
  color: var(--white);
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-vb-status-container-yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.col-vb-status-container-green {
  background-color: var(--green);
  color: var(--white);
}

.col-vb-status-container-orange {
  background-color: var(--orange);
  color: var(--white);
}

.col-vb-modal {
  position: absolute;
  top: 25%;
  left: 5%;
  right: 5%;
  bottom: 25%;
  background-color: var(--white);
  border-radius: 4px;
  padding: 10px;
}

.col-vb-modal-overlay {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.col-vb-modal-container {
  display: flex;
  flex-direction: column;
}

.col-vb-image-container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.col-vb-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

.add-col-vb-btn {
  margin-top: 15px;
  align-self: flex-end;
  color: var(--white);
  background-color: var(--btn-green);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--btn-green);
}

.add-col-vb-btn-left {
  align-self: center;
  margin-top: 3em;
  height: 35px;
}

.add-col-vb-btn-column {
  margin-top: 10px;
}

.add-col-vb-btn:hover {
  color: var(--btn-green);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--btn-green);
  border-width: 1px;
}

.col-vb-img {
  width: 100%;
  height: 100%;
}

.add-review-label {
  margin-bottom: 5px;
  color: var(--red);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.col-vb-rest-name {
  text-align: center;
  font-size: 1.5rem;
  color: var(--black);
  font-weight: 600;
}
.col-vb-rest-add {
  text-align: center;
  font-size: 14px;
  color: var(--black);
  margin-bottom: 15px;
}

.col-vb-brand-label {
  font-size: 14px;
  align-self: flex-end;
  font-weight: 600;
  color: var(--pastel-004);
}

@media (max-width: 700px) {
  .col-vb-screen-label {
    font-size: 1.7em;
  }
  .add-col-vb-btn {
    width: 50%;
  }

  .add-col-vb-row {
    margin-left: 0%;
    margin-right: 0%;
  }

  .col-vb-modal {
    top: 20%;
    bottom: 20%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .col-vb-screen-label {
    font-size: 3em;
  }
  .add-col-vb-btn {
    width: 50%;
  }
}
