@import url("../../../App.css");
.cmc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cmc-content-container {
  width: 80%;
  align-self: center;
}

.cmc-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.cmc-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-color: var(--pastel-001);
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 7px 10px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1), -0.4em 0 0.4em rgba(0, 0, 0, 0.1);
}

.cmc-img-container {
  width: 10%;
  height: 5%;
}

.cmc-logo-container {
  width: 15%;
  height: 15%;
}

.cmc-icon-container {
  width: 3%;
  height: 3%;
}

.cmc-img {
  width: 100%;
  height: 100%;
}

.cmc-card-title {
  font-size: 1.2rem;
  color: var(--pastel-003);
  font-weight: 600;
}

.cmc-row-container0 {
  border-color: #ffb90f;
}

.cmc-row-container1 {
  border-color: #ff8c00;
}

.cmc-row-container2 {
  border-color: #a2be0c;
}

.cmc-row-container3 {
  border-color: #e084a3;
}

.cmc-row-container4 {
  border-color: #741b47;
}

.cmc-row-container5 {
  border-color: #ffb90f;
}

.cmc-row-container6 {
  border-color: #ff8c00;
}

.cmc-row-container7 {
  border-color: #a2be0c;
}

.cmc-row-container8 {
  border-color: #e084a3;
}

.cmc-row-container9 {
  border-color: #741b47;
}

.cmc-row-container0 {
  border-color: #ffb90f;
}

.cmc-img-container-white {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
}
.cmc-img-container0 {
  width: 100%;
  height: 50px;
  background-color: #ffb90f;
  border-radius: 5px;
}

.cmc-img-container1 {
  width: 100%;
  height: 50px;
  background-color: #ff8c00;
  border-radius: 5px;
}

.cmc-img-container2 {
  width: 100%;
  height: 50px;
  background-color: #a2be0c;
  border-radius: 5px;
}

.cmc-img-container3 {
  width: 100%;
  height: 50px;
  background-color: #e084a3;
  border-radius: 5px;
}

.cmc-img-container4 {
  width: 100%;
  height: 50px;
  background-color: #741b47;
  border-radius: 5px;
}

.cmc-img-container5 {
  width: 100%;
  height: 50px;
  background-color: #ffb90f;
  border-radius: 5px;
}

.cmc-img-container6 {
  width: 100%;
  height: 50px;
  background-color: #ff8c00;
  border-radius: 5px;
}

.cmc-img-container7 {
  width: 100%;
  height: 50px;
  background-color: #a2be0c;
  border-radius: 5px;
}

.cmc-img-container8 {
  width: 100%;
  height: 50px;
  background-color: #e084a3;
  border-radius: 5px;
}

.cmc-img-container9 {
  width: 100%;
  height: 50px;
  background-color: #741b47;
  border-radius: 5px;
}

@media (max-width: 700px) {
  .cmc-logo-container {
    width: 25%;
    height: 25%;
  }

  .cmc-img-container {
    width: 20%;
  }

  .cmc-icon-container {
    width: 7%;
    height: 7%;
  }

  .cmc-content-container {
    width: 95%;
    align-self: center;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .cmc-logo-container {
    width: 20%;
    height: 20%;
  }

  .cmc-img-container {
    width: 15%;
  }

  .cmc-icon-container {
    width: 5%;
    height: 5%;
  }

  .cmc-content-container {
    width: 90%;
    align-self: center;
  }
}
