@import url("../../App.css");

.common-checkbox-container {
  width: 100%;
  font-size: 14px;
  color: var(--black);
  margin-bottom: 10px;
}

.common-checkbox {
  font-size: 14px;
  color: var(--black);
}

.common-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
}
