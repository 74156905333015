@import url("../../../../App.css");

.as-permission-cb-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.as-permission-cb-row-item {
  margin-right: 10px;
  margin-top: 5px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.as-feature-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.as-feature-add-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.75px;
  color: var(--pastel-002);
  border-color: var(--pastel-002);
  padding: 5px 15px;
  margin-left: 10px;
  cursor: pointer;
}

.as-feature-item-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.as-feature-item-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.as-feature-item-row-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--pastel-002);
  border-color: var(--pastel-002);
  border-radius: 5px;
  border-width: 0.75px;
  border-style: solid;
  margin-right: 5px;
  margin-top: 5px;
}
