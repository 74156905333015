@import url("../../../../App.css");

.olb-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.olb-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.olb-img {
  width: 100%;
  height: 100%;
}

.olb-img-container {
  width: 20px;
  height: 20px;
}

.olb-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.olb-blue-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue-general);
}

.olb-orange-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--orange-warning);
}

.olb-green-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--green-success);
}

.olb-red-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--red-error);
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
