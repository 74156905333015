@import url("../../../App.css");
.smc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.smc-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.smc-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-color: var(--pastel-003);
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  margin-bottom: 5px;
  padding: 4px;
}

.smc-img-container {
  width: 10%;
  height: 5%;
}

.smc-logo-container {
  width: 15%;
  height: 15%;
}

.smc-icon-container {
  width: 3%;
  height: 3%;
}

.smc-img {
  width: 100%;
  height: 100%;
}

.smc-card-title {
  font-size: 15px;
  color: var(--pastel-004);
  font-weight: 600;
  margin-top: 10px;
}

@media (max-width: 700px) {
  .smc-logo-container {
    width: 25%;
    height: 25%;
  }

  .smc-img-container {
    width: 20%;
  }

  .smc-icon-container {
    width: 7%;
    height: 7%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .smc-logo-container {
    width: 20%;
    height: 20%;
  }

  .smc-img-container {
    width: 15%;
  }

  .smc-icon-container {
    width: 5%;
    height: 5%;
  }
}
