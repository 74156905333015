@import url("../../../App.css");

.aemp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.aemp-input-half {
  width: 49%;
}

.input-row-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2em;
}

.aemp-input {
  width: 100%;
  margin-bottom: 1.2em;
}

.aemp-input-text {
  font-size: 13px;
  color: black;
}

.aemp-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.aemp-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.aemp-select {
  width: 100%;
}

.aemp-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: bold;
}

.aemp-text {
  font-size: 14px;
  color: black;
}

.aemp-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
  color: var(--pastel-004);
}

.aemp-checkbox-label-black {
  color: var(--black);
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
