@import url("../../../../App.css");

.add-p-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #e6e6e6;
}

.ap-padding {
  padding: 1em 0.5em 10px 0.5em;
}

.p-input {
  width: 100%;
  margin-bottom: 1em;
  font-size: 14px;
}

.p-row-input {
  width: 49%;
  font-size: 14px;
}

.p-check-box {
  width: 30%;
}

.p-select {
  font-size: 14px;
  width: 100%;
  margin-bottom: 1em;
}

.p-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
}

.p-select-no-margin {
  margin-bottom: 0px;
}

.p-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.p-select-container-full {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.p-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.p-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.p-input-row-wrap {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.p-input-text {
  font-size: 14px;
  color: black;
}

.p-text {
  font-size: 14px;
  color: black;
}

.p-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
  margin-top: 1.2em;
}

.p-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.p-button-right {
  margin-top: 15px;
  width: 40%;
  align-self: flex-end;
  background-color: var(--pastel-004);
  font-size: 14px;
  color: var(--white);
  font-weight: 500;
}

.p-button-right:hover {
  color: var(--pastel-004);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-004);
  border-width: 1px;
}

.p-button-small {
  width: 30%;
  font-size: 13px;
  background-color: var(--pastel-002);
  color: var(--white);
}

.p-button-small:hover {
  width: 30%;
  font-size: 13px;
  background-color: var(--white);
  color: var(--pastel-002);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-style: solid;
  border-width: 1px;
}

.ap-add-icon-container {
  width: 32px;
  height: 32px;
}

.ap-option-list {
  padding: 5px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.ap-option-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ap-option-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0.5em 5px 1em;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
  background-color: #f6f6f6;
}

.ap-option-item-default-container {
  align-self: flex-end;
}

.ap-option-item-default {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray);
}

.ap-option-item-name {
  font-size: 15px;
  font-weight: 600;
  color: var(--black);
}

.ap-option-item-description {
  font-size: 12px;
  color: var(--gray);
}

.ap-option-item-price {
  font-size: 13.5px;
  color: var(--white);
  background-color: var(--green-success);
  padding: 2px 10px;
  text-align: center;
  border-radius: 4px;
}

.ap-choice-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  margin-bottom: 5px;
}

.ap-choice-label {
  font-size: 15px;
  color: var(--black);
  margin-left: 5px;
}

@media (max-width: 700px) {
  .p-button-small {
    width: 30%;
    font-size: 12px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .p-button-small {
    width: 30%;
  }
}
