@import url("../../../../App.css");

.sc-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.add-sc-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.marg-right {
  margin-right: 10px;
  width: 65%;
}

.add-sc-btn {
  color: var(--white);
  background-color: var(--red);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--red);
}

.add-sc-btn:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.sc-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sc-card {
  width: 15em;
  border-color: var(--blue);
  border-width: 1px;
  padding: 7px;
  margin-right: 5px;
}

.sc-card-title {
  font-size: 15px;
  color: var(--blue);
  font-weight: 600;
  margin-top: 10px;
}

.sc-card-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

.sc-active {
  color: green;
  font-size: 13px;
  font-weight: 500;
}

.sc-in-active {
  color: red;
  font-size: 13px;
  font-weight: 500;
}

.sc-card-btn {
  color: var(--white);
  background-color: var(--blue);
  font-size: 13px;
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--blue);
}

.sc-card-btn:hover {
  color: var(--blue);
  background-color: var(--white);
  border-color: var(--blue);
}

@media (max-width: 700px) {
  .add-sc-btn {
    width: 50%;
  }

  .sc-card {
    width: 12em;
    margin-bottom: 5px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .add-sc-btn {
    width: 50%;
  }
}
