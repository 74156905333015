.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4em;
  margin-bottom: 10px;
}

.button-row-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4em;
  margin-bottom: 10px;
}
