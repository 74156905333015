@import url("../../../App.css");

.normal-btn {
  width: 45%;
  background-color: var(--pastel-002);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
  margin-bottom: 5px;
}

.normal-btn:hover {
  color: var(--pastel-002);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

.inverted-btn {
  width: 45%;
  background-color: var(--white);
  font-size: 16px;
  color: var(--pastel-002);
  height: 45px;
  font-weight: 500;
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
  margin-bottom: 5px;
}

.inverted-btn:hover {
  color: var(--white);
  background-color: var(--pastel-002);
  border-radius: 4px;
  border-color: var(--pastel-002);
  border-width: 1px;
}

@media (max-width: 700px) {
  .normal-btn {
    font-size: 14px;
  }
  .inverted-btn {
    font-size: 14px;
  }
  .normal-btn-bg {
    font-size: 14px;
    width: 60%;
  }
  .inverted-btn-bg {
    font-size: 14px;
    width: 60%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .normal-btn {
    font-size: 14px;
  }
  .inverted-btn {
    font-size: 14px;
  }
  .normal-btn-bg {
    font-size: 14px;
    width: 60%;
  }
  .inverted-btn-bg {
    font-size: 14px;
    width: 60%;
  }
}
