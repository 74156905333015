@import url("../../../../App.css");

.iap-row-input {
  width: 49%;
  font-size: 14px;
}

.iap-input-text {
  font-size: 14px;
  color: black;
}

.iap-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
}
