@import url("../../../../App.css");

.ld-text {
  font-size: 16px;
  color: var(--black);
}

.ld-grey-text {
  color: var(--grey);
}

.ld-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ld-create-access-button {
  font-size: 15px;
  color: var(--pastel-004);
  padding: 4px 15px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--pastel-004);
  text-align: center;
  cursor: pointer;
}

.ld-submit-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
