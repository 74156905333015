@import url("../../../App.css");

.eor-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.eor-btn {
  color: var(--white);
  background-color: var(--red);
  font-size: 14px;
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--red);
}

.eor-btn:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.eor-select-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.eor-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.eor-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.eor-list-select {
  font-size: 13px;
  width: 100%;
}

.eor-cat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 5px;
}

.eor-cat-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: var(--red);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  background-color: var(--white);
  color: var(--red);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 5px;
}

.eor-table-col-title {
  color: var(--pastel-004);
  font-size: 16px;
}

.eor-table-col-item {
  color: var(--black);
  font-size: 14px;
}

.eor-table-row-container {
  cursor: pointer;
}

.eor-button-row {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2em;
}

.eor-button {
  width: 40%;
  background-color: var(--red);
  font-size: 16px;
  color: var(--white);
  height: 45px;
  font-weight: 500;
  border-color: var(--red);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.eor-button:hover {
  color: var(--red);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
}

.eor-update-qty-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.eor-img-container {
  width: 24px;
  height: 24px;
}

.eor-img {
  width: 100%;
  height: 100%;
}

.eor-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10rem;
  margin-right: 10rem;
}

.eor-row-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  border: 0.2px;
  border-color: var(--gray);
  border-style: solid;
  width: 50%;
  font-size: 14px;
}

.eor-row-item-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 5px;
  border: 0.2px;
  border-color: var(--gray);
  border-style: solid;
  width: 50%;
  padding-right: 10px;
  font-size: 14px;
}

.eor-input {
  font-size: 14px;
}

@media (max-width: 700px) {
  .eor-btn {
    width: 50%;
  }

  .eor-button {
    font-size: 14px;
  }

  .eor-row {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .eor-button {
    font-size: 14px;
  }

  .eor-row {
    align-items: center;
    margin-left: 5rem;
    margin-right: 5rem;
  }
}
