@import url("../../../App.css");

.frd-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1.2rem;
}

.frd-title {
  font-size: 1.7rem;
  color: var(--pastel-004);
  font-weight: 700;
}

.frd-description {
  font-size: 18px;
  color: var(--black);
  margin: 5px 0px;
}

.frd-vote-label {
  background-color: var(--pastel-002);
  font-size: 16px;
  color: var(--white);
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
}

.frd-no-comment-label {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--pastel-003);
}

.frd-comments-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--pastel-003);
  margin-bottom: 10px;
}

.frd-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--gray);
  padding: 10px 15px;
  margin: 2px 10px 3px 10px;
}

.frd-comment-date-label {
  font-size: 12px;
  color: var(--gray);
  align-self: flex-end;
}

.frd-comment-message {
  font-size: 16px;
  color: var(--black);
}

.frd-comment-message-name {
  font-size: 14px;
  color: var(--black);
}
