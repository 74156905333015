@import url("../../../../../App.css");

.oml-qr-btn-container {
  display: flex;
  flex-direction: row-reverse;
}

.oml-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: var(--white);
}

.oml-qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  height: fit-content;
  width: fit-content;
  text-align: center;
  background-color: var(--white);
}

.oml-powered-label {
  font-weight: 400;
  width: 100%;
  color: var(--black);
  font-style: italic;
  text-align: end;
}
