@import url("../../../App.css");

.r-form-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  margin-top: 1.7rem;
  background-color: #f4f4f4;
  padding: 2rem 1.2rem;
  border-radius: 4px;
}

.r-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 1.7rem;
}

.r-condition-label {
  text-align: center;
  font-size: 14px;
}

.r-phone-input {
  margin-bottom: 10px;
}

.sign-up-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.sign-up-img-layout-container {
  width: 50%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-content-layout-container {
  width: 50%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 3rem;
}

.sign-up-logo-container {
  height: 50px;
  margin-bottom: 2rem;
}

.sign-up-img-container {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sign-up-tab-img-container {
  display: none;
}

.sign-up-brand-label {
  display: none;
}

@media only screen and (max-width: 767px) {
  .r-form-content {
    width: 95%;
  }
  .r-img-contain {
    width: 40%;
    height: 40%;
  }

  .sign-up-parent-container {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
  }

  .sign-up-img-layout-container {
    display: none;
  }

  .sign-up-content-layout-container {
    width: 100%;
    height: 100vh;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1rem;
    overflow-y: auto;
  }

  .sign-up-brand-label {
    display: none;
  }

  .sign-up-img-container {
    display: none;
  }

  .sign-up-tab-img-container {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .r-form-content {
    width: 90%;
  }
  .r-img-contain {
    width: 25%;
    height: 30%;
  }

  .sign-up-parent-container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .sign-up-img-layout-container {
    width: 30%;
    height: 100%;
    background-color: var(--orange);
  }

  .sign-up-content-layout-container {
    width: 70%;
    height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sign-up-brand-label {
    text-orientation: sideways;
    writing-mode: vertical-rl;
    white-space: nowrap;
    font-size: 6rem;
    font-weight: 700;
    color: var(--white);
    display: flex;
    letter-spacing: 6px;
  }

  .sign-up-tab-img-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .sign-up-img-container {
    display: none;
  }
}
