@import url("../../../App.css");

.fp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.fp-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
