@import url("../../../App.css");

.feature-item-container {
  border-radius: 10px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--white);
  background: var(--white);
  height: 270px;
  margin: 5px;
}

.feature-item-content-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 15px;
}

.feature-more-img {
  width: 64px;
  height: 64px;
}

.feature-item-label {
  font-size: 1.1rem;
  margin-top: 1.5rem;
  margin-bottom: 5px;
  font-weight: 600;
}

.feature-item-content-description {
  font-size: 1.1rem;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 300;
}
