@import url("../../../App.css");
.contentAreaAgentSignIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.asi-img-contain {
  width: 12%;
  height: 5%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.asi-img {
  height: 100%;
  width: 100%;
}

.asi-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4em;
}

.asi-input {
  font-size: 15px;
}

@media (max-width: 700px) {
  .asi-img-contain {
    width: 40%;
    height: 40%;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .asi-img-contain {
    width: 40%;
    height: 40%;
  }
}
