@import url("../../../App.css");

.bl-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.bl-erroLabelContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bl-errorLabel {
  font-size: 15px;
  color: var(--pastel-004);
}

.bl-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
