@import url("../../../App.css");

.refresh-container {
  height: 25px;
  padding-bottom: 5px;
  border-color: var(--pastel-002);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 35%;
  align-items: center;
  cursor: pointer;
}

.refresh-img {
  width: 100%;
  height: 100%;
}

.refresh-img-container {
  width: 20px;
  height: 20px;
}

.refresh-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--pastel-002);
  margin-top: 5px;
  margin-left: 5px;
}
